import React, { useState } from "react";
import { GoogleLogin } from "@react-oauth/google";
import "./loginpage.css"; // Use your custom styles here
import "bootstrap/dist/css/bootstrap.min.css";
import "@fontsource/poppins"; // Custom font if needed

export default function LoginPage() {
  const [isLogin, setIsLogin] = useState(true);
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [isLoginPasswordVisible, setIsLoginPasswordVisible] = useState(false);

  const [signupName, setSignupName] = useState("");
  const [signupEmail, setSignupEmail] = useState("");
  const [signupPassword, setSignupPassword] = useState("");
  const [isSignupPasswordVisible, setIsSignupPasswordVisible] = useState(false);

  const handleLoginEmailChange = (e) => setLoginEmail(e.target.value);
  const handleLoginPasswordChange = (e) => setLoginPassword(e.target.value);
  const handleSignupNameChange = (e) => setSignupName(e.target.value);
  const handleSignupEmailChange = (e) => setSignupEmail(e.target.value);
  const handleSignupPasswordChange = (e) => setSignupPassword(e.target.value);

  const toggleLoginPasswordVisibility = () =>
    setIsLoginPasswordVisible(!isLoginPasswordVisible);

  const toggleSignupPasswordVisibility = () =>
    setIsSignupPasswordVisible(!isSignupPasswordVisible);

  const handleLogin = async () => {
    if (!loginEmail || !loginPassword) {
      alert("Please enter your email and password");
      return;
    }

    const response = await fetch("http://localhost:5000/api/auth/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: loginEmail, password: loginPassword }),
    });

    const data = await response.json();

    if (response.ok) {
      alert("Login successful!");
      localStorage.setItem("token", data.token);
    } else {
      alert(data.message);
    }
  };

  const handleSignup = async () => {
    if (!signupName || !signupEmail || !signupPassword) {
      alert("Please fill in all the details");
      return;
    }

    const response = await fetch("http://localhost:5000/api/auth/register", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: signupName,
        email: signupEmail,
        password: signupPassword,
      }),
    });

    const data = await response.json();

    if (response.ok) {
      alert("Signup successful!");
      setIsLogin(true);
    } else {
      alert(data.message);
    }
  };

  const toggleForm = (formType) => {
    setIsLogin(formType === "login");
  };

  return (
    <div className="login-page flex flex-col lg:flex-row">
      {/* Left Section */}
      <div className="left-section w-full lg:w-1/2 p-8 bg-gray-100 flex-col justify-center items-start">

        <h1 className="text-4xl font-bold text-white-800 mb-4">
          Welcome to Schollers AI
        </h1>
        <p className="text-white-600 text-lg mb-6">
          Empowering Businesses Across Industries with Schollers' Advanced AI
          Technology. Get started today!
        </p>
        <ul className="features-list space-y-4">
          <li className="flex items-center">
            <i className="bi bi-robot text-indigo-600 text-xl mr-3"></i>
            <span className="text-white-700 text-lg">AI-Powered Environment</span>
          </li>
          <li className="flex items-center">
            <i className="bi bi-bar-chart-line text-indigo-600 text-xl mr-3"></i>
            <span className="text-white-700 text-lg">Grow with Schollers Intelligence</span>
          </li>
          <li className="flex items-center">
            <i className="bi bi-book text-indigo-600 text-xl mr-3"></i>
            <span className="text-white-700 text-lg">24/7 Customer Centricity</span>
          </li>
        </ul>
      </div>


      {/* Right Section */}
      <div className="right-section w-full lg:w-1/2 flex items-center justify-center p-8">
        <div className="max-w-md w-full">
          {isLogin ? (
            <div className="space-y-6">
              <div className="text-center mb-8">
                <h2 className="text-3xl font-bold text-gray-800">Sign in to SAI</h2>
                <p className="text-gray-600 mt-2">Continue your learning journey</p>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Email Address
                </label>
                <input
                  type="email"
                  value={loginEmail}
                  onChange={handleLoginEmailChange}
                  required
                  className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Password
                </label>
                <div className="relative">
                  <input
                    type={isLoginPasswordVisible ? "text" : "password"}
                    value={loginPassword}
                    onChange={handleLoginPasswordChange}
                    required
                    className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 pr-10"
                  />
                  <span
                    onClick={toggleLoginPasswordVisibility}
                    className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                  >
                    {isLoginPasswordVisible ? (
                      <i className="bi bi-eye-slash-fill text-gray-500"></i>
                    ) : (
                      <i className="bi bi-eye-fill text-gray-500"></i>
                    )}
                  </span>
                </div>
              </div>
              <button
                onClick={handleLogin}
                className="w-full py-3 px-4 border border-transparent rounded-lg text-white gradient-bg hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 font-medium"
              >
                Login
              </button>
              <div className="relative my-6">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300"></div>
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 bg-gray-50 text-gray-500">Or continue with</span>
                </div>
              </div>
              <GoogleLogin
                onSuccess={(credentialResponse) => {
                  alert("Logged in with Google");
                  console.log(credentialResponse);
                }}
                onError={() => {
                  alert("Error logging in with Google");
                }}
                className="w-full flex items-center justify-center py-3 px-4 border border-gray-300 rounded-lg text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 font-medium"
              />
              <p className="text-center text-sm text-gray-600 mt-4">
                Don't have an account?{" "}
                <a
                  href="#"
                  onClick={() => toggleForm("signup")}
                  className="font-medium text-indigo-600 hover:text-indigo-500"
                >
                  Sign up
                </a>
              </p>
            </div>
          ) : (
            <div className="space-y-6">
              <div className="text-center mb-8">
                <h2 className="text-3xl font-bold text-gray-800">Create Account</h2>
                <p className="text-gray-600 mt-2">Join the Scholars AI community</p>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Full Name
                </label>
                <input
                  type="text"
                  value={signupName}
                  onChange={handleSignupNameChange}
                  required
                  className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Email Address
                </label>
                <input
                  type="email"
                  value={signupEmail}
                  onChange={handleSignupEmailChange}
                  required
                  className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Password
                </label>
                <div className="relative">
                  <input
                    type={isSignupPasswordVisible ? "text" : "password"}
                    value={signupPassword}
                    onChange={handleSignupPasswordChange}
                    required
                    className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 pr-10"
                  />
                  <span
                    onClick={toggleSignupPasswordVisibility}
                    className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                  >
                    {isSignupPasswordVisible ? (
                      <i className="bi bi-eye-slash-fill text-gray-500"></i>
                    ) : (
                      <i className="bi bi-eye-fill text-gray-500"></i>
                    )}
                  </span>
                </div>
              </div>
              <button
                onClick={handleSignup}
                className="w-full py-3 px-4 border border-transparent rounded-lg text-white gradient-bg hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 font-medium"
              >
                Sign Up
              </button>
              <p className="text-center text-sm text-gray-600 mt-4">
                Already have an account?{" "}
                <a
                  onClick={() => toggleForm("login")}
                  className="font-medium text-indigo-600 hover:text-indigo-500"
                >
                  Sign in
                </a>
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
