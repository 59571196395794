import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';  // Correct imports
import Main from './components/main';  // Main page component
import RefundAndCancellation from './components/refund';  // Refund and Cancellation page component
import Contact from './components/contact';  // Contact page component
import Terms from './components/terms';  // Terms and Conditions page component

import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        
        <Routes>
          <Route path="/" element={<Main />} />  
          <Route path="/refund-cancellation" element={<RefundAndCancellation />} />  
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/terms-conditions" element={<Terms />} />  
        </Routes>
      </div>
    </Router>
  );
}

export default App;




// import React from "react";

// const App = () => {
//   return (
//     <div className="flex items-center justify-center min-h-screen bg-gradient-to-r from-blue-600 to-purple-600 text-white px-4">
//       <div className="text-center">
//         <h1 className="text-6xl font-bold mb-6">Under Maintenance</h1>
//         <p className="text-xl mb-4">
//           Our site is currently undergoing scheduled maintenance. We’ll be back
//           shortly!
//         </p>
//         <p className="text-lg mb-8">
//           For inquiries, feel free to reach out:{" "}
//           <a
//             href="mailto:schollerapp@gmail.com"
//             className="underline text-yellow-300 hover:text-yellow-500"
//           >
//             schollerapp@gmail.com
//           </a>
//         </p>
//         <div className="mt-8">
//           <svg
//             xmlns="http://www.w3.org/2000/svg"
//             className="h-64 w-64 mx-auto"
//             fill="none"
//             viewBox="0 0 24 24"
//             stroke="currentColor"
//             strokeWidth={2}
//           >
//             <path
//               strokeLinecap="round"
//               strokeLinejoin="round"
//               d="M9.75 3.75a3 3 0 015.5 0m-8.5 16.5h11a2 2 0 002-2V9.75M6.25 19.25A2 2 0 004.25 17V9.75a2 2 0 011.997-2M4.25 6.75V3.75M19.75 6.75V3.75m-15.5 3h15.5"
//             />
//           </svg>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default App;
